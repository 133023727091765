// Common file mime types
const MIME_TYPES = {
    csv: 'text/csv',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const FileUtil = {

    saveByteArray(fileName, byte, mimeType) {
        let blob = new Blob([byte], { type: MIME_TYPES[mimeType] });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    },

    getUrlFileName(url) {
        let decodedUrl = decodeURIComponent(decodeURIComponent(url));
        return decodedUrl.split('/').pop().split(/.(jpe?g|png)/)[0];
    },

    isValidImgFileType(file) {
        const fileTypes = ['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'];
        const extension = file.name.split('.').pop().toLowerCase();
        return fileTypes.indexOf(extension) > -1;
    },

    isValidImgPNG(img) {
        return img && img.type === "image/png";
    }
}