import { db } from '@/config/firebase';

function generateQuery(filter) {
    let query = db.collection("maintenances");

    if (filter.maintenanceId && filter.maintenanceId.length > 0) {
        query = query.where("maintenanceId", "==", filter.maintenanceId);
    } else {
        if (filter.fromTimestamp && filter.fromTimestamp > 0) {
            query = query.where("dateCreated", ">=", filter.fromTimestamp);
        }

        if (filter.toTimestamp && filter.toTimestamp > 0) {
            query = query.where("dateCreated", "<=", filter.toTimestamp);
        }

        if (filter.company && filter.company.id && filter.company.id.length > 0) {
            query = query.where("companyId", "==", filter.company.id);
        }
        
        if (filter.storageLocation && filter.storageLocation.id && filter.storageLocation.id.length > 0) {
            query = query.where("storageLocationId", "==", filter.storageLocation.id);
        }

        if (filter.status && filter.status.length > 0) {
            query = query.where("status", "==", filter.status);
        }

        if (filter.assetType && filter.assetType.id && filter.assetType.id.length > 0) {
            query = query.where("assetTypeId", "==", filter.assetType.id);
        }
    }
    
    return query;
}

function getMaintenanceListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            callback(change.type, change.doc.data());
        });
    });
    return listener;
}

async function getBatches(id) {
    const parentRef = db.collection('maintenances').doc(id);
    const batchesRef = parentRef.collection('repairedBatches');
    const snapshot = await batchesRef.get();
        
    const batchesObj = [];
        
    snapshot.forEach((doc) => {
        batchesObj[doc.id] = doc.data();
    });

    return batchesObj;
}


async function saveBatches(batches, parentId) {
    const parentRef = db.collection('maintenances').doc(parentId);
    const batchesRef = parentRef.collection('repairedBatches');
    const batchesObj = {};

    for (const batch of batches) {
        const subCollectionId = batch.id || batchesRef.doc().id; // Use existing ID or create a new one

        const batchData = {
            ...batch,
            parentRef: parentRef,
            collectionName: 'maintenances',
            parentId: parentId,
            id: subCollectionId,
        };

        const batchRef = batchesRef.doc(subCollectionId);

        if (await batchRef.get().exists) {
            await batchRef.update(batchData);
        } else {
            await batchRef.set(batchData);
        }

        batchesObj[subCollectionId] = batchData;
    }

    return batchesObj;
}


export default {
    getMaintenanceListener,
    saveBatches,
    getBatches
}