<template>
	<b-modal
		:title="`${getImageName}`"
		id="image-view-dialog"
		:no-close-on-backdrop="true"
		size="md"
		:hide-footer="true"
		centered
	>
		<b-row>
			<b-col sm="12">
				<div align="center">
					<b-img
						v-img-orientation-changer
						:src="imageUrl"
						alt="Responsive image"
						class="img-responsive"
						fluid
					/>
				</div>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
// Others
import EventBus from '@/shared/event-bus';
import _ from 'lodash';

export default {
	name: 'image-view-dialog',
	data() {
		return {
			imageUrl:'',
			imageName:'',
		};
	},
	computed: {
		getImageName() {
			if (!_.isEmpty(this.imageName)) {
				return this.imageName;
			}
			return '';
		},
	},
	mounted() {
		EventBus.$on('onSelectImageView', (params) => {
			this.imageUrl = params.url;
			this.imageName = params.name;
		});
	},
	beforeDestroy() {
		EventBus.$off('onSelectImageView');
	},
};
</script>

<style scoped>
.thumbnail {
	position: relative;
}
.caption {
	position: absolute;
	bottom: 0px;
	left: -10px;
	width: 100%;
}
.strokeme {
	color: white;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
		1px 1px 0 #000;
}
</style>