import config from '@/config/env-constants';


export const MaintenanceUtil = {

	getMaintenanceDefaultObj() {
		return {
			maintenanceId: '',
			dispatchId: '',
			assetType: '',
			assetTypeId: '',
			area: {},
			conditions: [],
			documents: [],
			dateCreated: 0,
			createdBy: '',
			dateUpdated: 0,
			updatedBy: '',
			dateCancelled: 0,
			cancelledBy: '',
			status: 'Open'
		};
	},

	getBatchDefaultObj() {
		return {
			maintenanceId: '',
			batchNo: 0,
			classifications: [],
			status: 'In Production',
			repaired: {
				document: {},
				remarks: '',
				dateRepaired: 0,
				repairedBy: '',
			},
			checked: {
				document: {},
				remarks: '',
				dateChecked: 0,
				checkedBy: '',
			},
			endorsed: {
				document: {},
				remarks: '',
				dateEndorsed: 0,
				endorsedBy: '',
			},
			completed: false,
			dateUpdated: 0,
			updatedBy: '',
			dateCreated: 0,
			createdBy: '',
		}
	},

	getCompanyLocationDisplay(area) {
		return area.company + ' (' + area.storageLocation + ')';
	},

	getRepairedAssetCount(batches) {
		let total = 0;
		if (batches != null){
			for (const batch of batches) {
				if (batch.status !== config.batchStatus.CANCELLED.name && batch.repairedBy) {
					total += (batch.repaired - batch.rejected);
				}
			}
		}
		return total;
	},

	getCheckedAssetCount(batches) {
		let total = 0;
		if (batches != null){
			for (const batch of batches) {
				if (batch.status !== config.batchStatus.CANCELLED.name && batch.checkedBy) {
					total += batch.good;
				}
			}
		}
		return total;
	},

	getEndorsedAssetCount(batches) {
		let total = 0;
		if (batches != null){
			for (const batch of batches) {
				if (batch.status !== config.batchStatus.CANCELLED.name && batch.endorsedBy) {
					total += batch.good;
				}
			}
		}
		return total;
	},

	getBatchCountWith(status, batches) {
		let total = 0;
		if (batches != null){
			for (const batch of batches) {
				if (batch.status === status) {
					total++;
				}
			}
		}
		return total;
	},

	getMaintenanceStatus() { 
		return [
			{
				value: null,
				text: ' - Please select - '
			},
			{
				value: 'Open',
				text: 'Open'
			},
			{
				value: 'In-Repair',
				text: 'In-Repair'
			},
			{
				value: 'Closed',
				text: 'Closed'
			},
			{
				value: 'Cancelled',
				text: 'Cancelled'
			}
		];
	}
}
